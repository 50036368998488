import EventEmitter from 'events';

const makeGlobalError = (msg: string) => ({ errors: { global: msg } });
export const events = new EventEmitter();

async function request(
   method = 'GET',
   url = '',
   data = {},
   headers: Record<string, string> = {},
): Promise<any> {
   try {
      const token = localStorage.getItem('token') || null;
      const fetchOptions = {
         method,
         mode: 'cors',
         cache: 'no-cache',
         credentials: 'same-origin',
         headers: new Headers({
            ...headers,
            Authorization: token ? `Bearer ${token}` : '',
            'Content-Type': 'application/json',
         }),
         redirect: 'follow',
         referrerPolicy: 'no-referrer',
         body: data ? JSON.stringify(data) : undefined,
      };

      if (method === 'GET') {
         delete fetchOptions.body;
      }

      const response = await fetch(url, fetchOptions as any);
      const content = await response.json();
      if (response.status === 403 && content.message === 'auth_failed') {
         events.emit('auth_failed');
      } else if (response.status === 404 && !content.errors) {
         return makeGlobalError('Oups... Impossible de trouver la route !');
      } else if ((response.status < 200 || response.status >= 400) && !content.errors) {
         return makeGlobalError("Oups... Une erreur inattendue s'est produite !");
      }
      return content;
   } catch (err) {
      return makeGlobalError("Oups... Une erreur inattendue s'est produite !");
   }
}

const esc = encodeURIComponent;
const queryUrl = (url = '', data: Record<string, any> = {}) => {
   const query = Object.keys(data)
      .map((k) => `${esc(k)}=${esc(String(data[k] as any))}`)
      .join('&')
      .trim();
   return url + (query.length > 0 ? `?${query}` : '');
};

export async function post(url = '', data = {}, headers = {}) {
   return request('POST', url, data, headers);
}

export async function get(url = '', data = {}, headers = {}) {
   return request('GET', queryUrl(url, data), undefined, headers);
}

export async function del(url = '', data = {}, headers = {}) {
   return request('DELETE', queryUrl(url, data), undefined, headers);
}

(window as any).api = {
   post,
   get,
   del,
};
