import React, { useContext, useRef, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { SongDto } from '../dto/song';

export type PlayerController = {
   playSong: (song: SongDto) => any;
   pauseSong: () => any;
   ref: React.MutableRefObject<AudioPlayer>;
   song: SongDto;
   status: 'paused' | 'playing';
   setStatus: (status: 'paused' | 'playing') => any;
};

export const PlayerContext = React.createContext<PlayerController>(null);
export const PlayerProvider = ({ children }: { children: any }) => {
   const [song, setSong] = useState<SongDto>(null);
   const [status, setStatus] = useState<'paused' | 'playing'>('paused');
   const ref = useRef<AudioPlayer>(null);

   const pause = () => {
      ref.current.audio.current.pause();
   };

   const play = () => {
      ref.current.audio.current.play();
   };

   return (
      <PlayerContext.Provider
         value={{
            song,
            playSong: (nsong) => {
               if (!song || nsong?.id !== song?.id) {
                  setSong(nsong);
               } else if (status === 'paused') {
                  play();
               }
            },
            pauseSong: pause,
            ref,
            status,
            setStatus: (newStatus) => {
               setStatus(newStatus);
            },
         }}
      >
         {children}
      </PlayerContext.Provider>
   );
};

export const usePlayer = () => useContext(PlayerContext);

const Player = () => {
   const player = usePlayer();
   return (
      <AudioPlayer
         autoPlay
         src={
            player?.song?.mp3Path
               ? `https://aujourlejour.s3.fr-par.scw.cloud/${player?.song.mp3Path}`
               : ``
         }
         style={{ boxShadow: 'none' }}
         ref={player.ref}
         onEnded={() => player.setStatus('paused')}
         onPause={() => player.setStatus('paused')}
         onPlay={() => player.setStatus('playing')}
      />
   );
};

export default Player;
