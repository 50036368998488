import React, { useState, useContext } from 'react';

export type SelectionManager = {
   selection: Record<string, boolean>;
   add: (id: string) => any;
   del: (id: string) => any;
};
const SelectionContext = React.createContext<SelectionManager>({
   selection: {},
   add: () => {},
   del: () => {},
});
export const useSelection = () => useContext(SelectionContext);

export const SelectionProvider = ({ children }: { children: any }) => {
   const [selection, setSelection] = useState<Record<string, boolean>>({});
   const add = (id: string) => setSelection({ ...selection, [id]: true });
   const del = (id: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [id]: _, ...nselection } = selection;
      setSelection(nselection);
   };

   return (
      <SelectionContext.Provider value={{ selection, add, del }}>
         {children}
      </SelectionContext.Provider>
   );
};
