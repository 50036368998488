import { Delete } from '@mui/icons-material';
import { Box, Button, ButtonProps, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { del } from '../../api';
import Modal from '../Modal';
import PrimaryButton from '../PrimaryButton';

type DeleteButtonProps = {
   url?: string;
   confirmationMessage?: any;
   successMessage?: any;
   returnUrl?: string;
   returnLabel?: any;
   children?: any;
   onSuccess?: () => void;
   size?: ButtonProps['size'];
   mini?: boolean;
   round?: boolean;
   style?: React.CSSProperties;
};

const DeleteButton = ({
   style,
   url,
   round,
   mini,
   size,
   onSuccess,
   children,
   confirmationMessage,
   successMessage,
   returnUrl,
   returnLabel,
}: DeleteButtonProps) => {
   const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
   const [deleteStatus, setDeleteStatus] = useState<'confirm' | 'deleting' | 'deleted' | 'error'>(
      'confirm',
   );

   const confirmDelete = () => {
      setDeleteStatus('deleting');
      del(url).then((response) => {
         if (response.errors) {
            setDeleteStatus('error');
         } else setDeleteStatus('deleted');
      });
   };

   return (
      <>
         <PrimaryButton
            style={{ ...style }}
            round={round}
            mini={mini}
            size={size}
            variant="contained"
            color="error"
            onClick={() => setShowDeleteConfirm(true)}
         >
            <Delete style={{ fontSize: mini ? 14 : undefined }} />{' '}
            <Box ml={children ? 1 : 0}>{children}</Box>
         </PrimaryButton>
         <Modal open={showDeleteConfirm} onClose={() => setShowDeleteConfirm(false)}>
            {(() => {
               switch (deleteStatus) {
                  case 'confirm':
                     return (
                        <>
                           <p>
                              {confirmationMessage ??
                                 'Êtes vous sur de vouloir effectuer cette suppressoin ?'}
                           </p>
                           <Box display="flex" justifyContent="end">
                              <Button onClick={() => setShowDeleteConfirm(false)}>Annuler</Button>
                              <Button variant="contained" color="primary" onClick={confirmDelete}>
                                 Confirmer la suppression
                              </Button>
                           </Box>
                        </>
                     );
                  case 'deleting':
                     return (
                        <>
                           <Box mx={20} my={5}>
                              <CircularProgress />
                           </Box>
                        </>
                     );
                  case 'deleted':
                     return (
                        <>
                           <p>{successMessage ?? 'La suppression a bien été effectuée.'}</p>
                           <RouterLink
                              to={returnUrl}
                              onClick={
                                 returnUrl
                                    ? undefined
                                    : () => {
                                         setShowDeleteConfirm(false);
                                         setDeleteStatus('confirm');
                                         onSuccess?.();
                                      }
                              }
                           >
                              <Button variant="contained" color="primary">
                                 {returnLabel ?? 'Retour'}
                              </Button>
                           </RouterLink>
                        </>
                     );
                  case 'error':
                     return (
                        <>
                           <p>Oups... Une erreur inattendue s&apos;est produite !</p>
                           <Box display="flex" justifyContent="end">
                              <Button
                                 onClick={() => {
                                    setShowDeleteConfirm(false);
                                    setDeleteStatus('confirm');
                                 }}
                              >
                                 Annuler
                              </Button>
                           </Box>
                        </>
                     );
                  default:
                     return <p></p>;
               }
            })()}
         </Modal>
      </>
   );
};

export default DeleteButton;
