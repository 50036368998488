import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';
import MUIModal from '@mui/material/Modal';
import { Close } from '@mui/icons-material';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';

type ModalProps = {
   open?: boolean;
   onClose?: () => any;
   children?: any;
   noCloseButton?: boolean;
};

const Modal = ({ open, onClose, children, noCloseButton }: ModalProps) => (
   <Box>
      <StyledModel
         aria-labelledby="transition-modal-title"
         aria-describedby="transition-modal-description"
         open={open || false}
         onClose={onClose}
         closeAfterTransition
         BackdropComponent={Backdrop}
         BackdropProps={{
            timeout: 0,
         }}
      >
         <Fade in={open}>
            <Paper
               style={{
                  position: 'relative',
                  margin: '30px 0px',
                  overflow: 'auto',
                  outline: 'none',
                  maxHeight: '95vh',
                  maxWidth: '100vw',
                  padding: 20,
               }}
            >
               {!noCloseButton && (
                  <Close
                     fontSize="small"
                     onClick={onClose}
                     style={{
                        position: 'absolute',
                        right: 5,
                        top: 5,
                        cursor: 'pointer',
                     }}
                  />
               )}
               {children}
            </Paper>
         </Fade>
      </StyledModel>
   </Box>
);

const StyledModel = styled(MUIModal)({
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   outline: 'none',
});

export default Modal;
