// eslint-disable-next-line import/no-cycle, max-classes-per-file
import { SongDto } from './song';

export class AlbumDto {
   id: string;

   title: string;

   description: string;
}

export class AlbumWithSongsDto extends AlbumDto {
   songs: SongDto[];
}
