import React, { useState } from 'react';
import {
   Drawer,
   Button,
   List,
   ListItem,
   ListItemIcon,
   ListItemText,
   Collapse,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Close, Home, ExpandLess, ExpandMore } from '@mui/icons-material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link } from 'react-router-dom';
import AlbumIcon from '@mui/icons-material/Album';
import GroupIcon from '@mui/icons-material/Group';
import HistoryIcon from '@mui/icons-material/History';
import useAuth from '../hooks/useAuth';

const SideMenu = ({ open, close }: { open: boolean; close: () => any }) => {
   const theme = useTheme();
   const [openMenus, setOpenMenus] = useState<Record<string, boolean>>({});
   const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));
   const fullWidth = !isBigScreen;
   const { logout, user } = useAuth();

   const menuItems: {
      text?: string;
      to?: string;
      icon?: any;
      subMenu?: any[];
   }[] = [{ text: 'Accueil', to: '/', icon: <Home /> }];
   if (user.role === 'admin') {
      menuItems.push(
         { text: 'Gestion des albums', to: '/albums', icon: <AlbumIcon /> },
         { text: 'Gestion des utilisateurs', to: '/users', icon: <GroupIcon /> },
         { text: 'Historique', to: '/history', icon: <HistoryIcon /> },
      );
   }

   return (
      <Drawer
         open={open}
         variant={isBigScreen ? 'permanent' : 'temporary'}
         sx={{ width: fullWidth ? '100%' : '300px' }}
         PaperProps={{ sx: { width: fullWidth ? '100%' : '300px' } }}
      >
         {!isBigScreen && (
            <CloseButton onClick={close}>
               <Close />
            </CloseButton>
         )}
         <List style={{ marginTop: isBigScreen ? 0 : 30 }}>
            {menuItems.map(({ text, icon, to, subMenu }, index) => (
               <React.Fragment key={text}>
                  <ListItem
                     button
                     component={
                        to ? (props: any) => <Link to={to} {...props} onClick={close} /> : undefined
                     }
                     onClick={
                        subMenu
                           ? () => setOpenMenus({ ...openMenus, [index]: !openMenus[index] })
                           : undefined
                     }
                  >
                     <ListItemIcon>{icon as any}</ListItemIcon>
                     <ListItemText primary={text} />
                     {!!subMenu && (openMenus[index] ? <ExpandLess /> : <ExpandMore />)}
                  </ListItem>
                  {subMenu && (
                     <Collapse in={openMenus[index]} timeout="auto" unmountOnExit>
                        <List disablePadding>
                           {subMenu.map(({ text: itemText, to: itemTo }) => (
                              <ListItem
                                 dense
                                 button
                                 key={itemText}
                                 component={(props) => (
                                    <Link to={itemTo} {...props} onClick={close} />
                                 )}
                              >
                                 <ListItemText
                                    style={{ marginLeft: 60, color: '#777' }}
                                    primary={itemText}
                                 />
                              </ListItem>
                           ))}
                        </List>
                     </Collapse>
                  )}
               </React.Fragment>
            ))}
            <ListItem onClick={logout} button>
               <ListItemIcon>
                  <ExitToAppIcon />
               </ListItemIcon>
               <ListItemText primary="Déconnection" />
            </ListItem>
         </List>
      </Drawer>
   );
};

const CloseButton = styled(Button)({
   position: 'absolute',
   right: 5,
   width: 34,
   minWidth: 34,
   borderRadius: 50,
});

export default SideMenu;
