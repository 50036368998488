import ArchiveIcon from '@mui/icons-material/Archive';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Button,
   CircularProgress,
   Stack,
   Tab,
   Tabs,
   Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import { post } from '../api';
import routes from '../api/routes';
import AudioButton from '../components/AudioButton';
import { DownloadRequestHistoryDto } from '../dto/downloadRequest';
import useFetch from '../hooks/useFetch';

const History = () => {
   const [count, setCount] = useState(0);
   const [currentTab, setCurrentTab] = useState(0);
   const archiveAll = useCallback(async () => {
      await post(routes.songs.archiveAll);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setCount((c) => c + 1);
   }, []);

   return (
      <Box key={count} display="flex" justifyContent="center" sx={{ flexGrow: 1 }}>
         <Box maxWidth="950px" sx={{ flexGrow: 1, p: 4 }}>
            <Stack
               direction="row"
               sx={{
                  alignItems: 'start',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
               }}
            >
               <Typography variant="h5">Historique des téléchargements</Typography>
               <Button
                  variant="contained"
                  color="error"
                  onClick={archiveAll}
                  startIcon={<ArchiveIcon />}
               >
                  Archiver l&apos;historique
               </Button>
            </Stack>
            <Tabs value={currentTab} onChange={(_, newTab: number) => setCurrentTab(newTab)}>
               <Tab label="Historique récent" />
               <Tab label="Historique archivé" />
            </Tabs>
            {currentTab === 0 && <HistoryTabContent archived={false} />}
            {currentTab === 1 && <HistoryTabContent archived={true} />}
         </Box>
      </Box>
   );
};

const HistoryTabContent = ({ archived }: { archived: boolean }) => {
   const [isLoading, downloadHistory] = useFetch<DownloadRequestHistoryDto[]>(
      `${routes.songs.downloadHistory}?archived=${archived ? 'true' : 'false'}`,
   );

   // eslint-disable-next-line no-nested-ternary
   return isLoading ? (
      <Box display="flex" justifyContent="center" height="200px">
         <CircularProgress />
      </Box>
   ) : downloadHistory?.length === 0 ? (
      <Typography sx={{ p: 4 }}>Rien à afficher</Typography>
   ) : (
      <Box py={2}>
         {downloadHistory.map((entry, i) => (
            <Accordion key={entry.user.id + String(i)}>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack direction="row" flexWrap={'wrap'} flexGrow={1}>
                     <Typography sx={{ mr: 2 }}>
                        <strong>
                           {entry.user.firstName} {entry.user.lastName}
                        </strong>
                        , {dayjs(entry.timestamp).format('[le] DD/MM/YYYY [à] HH:mm')}
                     </Typography>
                     <Typography variant="body1" color="GrayText" sx={{ mr: 2 }}>
                        {entry.comment}
                     </Typography>
                     <Typography
                        variant="body1"
                        color="GrayText"
                        sx={{ justifySelf: 'end', flexGrow: 1, textAlign: 'right', pr: 1 }}
                     >
                        <strong>{entry.songs.length}</strong> fichiers
                     </Typography>
                  </Stack>
               </AccordionSummary>
               <AccordionDetails>
                  <Box
                     width="100%"
                     style={{
                        display: 'grid',
                        counterReset: 'grid-items',
                        gridTemplateColumns: 'repeat(auto-fill, minmax(min(350px, 85vw), 1fr))',
                        rowGap: '10px',
                        columnGap: '10px',
                        alignItems: 'stretch',
                     }}
                  >
                     {entry.songs.map((song) => (
                        <Box
                           key={song.id}
                           boxSizing="border-box"
                           style={{
                              background: '#FFF',
                              border: '1px solid #DDD',
                              boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.06)',
                              borderRadius: '2px',
                           }}
                           px={2}
                           py={0.5}
                           flexGrow={1}
                        >
                           <Box display="flex" justifyContent="space-between" alignItems="center">
                              <Box display="flex" alignItems="center">
                                 <Box mr={1}>
                                    {' '}
                                    <AudioButton padding={0} size={23} song={song} />
                                 </Box>
                                 {song.title} - {song.album.title}
                              </Box>
                           </Box>
                        </Box>
                     ))}
                  </Box>
               </AccordionDetails>
            </Accordion>
         ))}
      </Box>
   );
};

export default History;
