import { useCallback, useContext } from 'react';
import { UserContext } from '../components/user/UserProvider';
import { User } from '../types/user';

const useAuth = () => {
   const { user, setUser } = useContext(UserContext);

   const login = useCallback(
      (loginUser: User, token: string) => {
         localStorage.setItem('token', token);
         setUser(loginUser);
      },
      [setUser],
   );

   const logout = useCallback(() => {
      localStorage.removeItem('token');
      setUser(null);
   }, [setUser]);

   return { user, login, logout };
};

export default useAuth;
