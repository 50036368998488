import { Badge, Box, Button, CircularProgress } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import React, { useMemo, useState } from 'react';
import routes from '../api/routes';
import { DownloadForm } from '../components/DownloadForm';
import Modal from '../components/Modal';
import { useSelection } from '../components/Selection';
import { AlbumWithSongsDto } from '../dto/album';
import useFetch from '../hooks/useFetch';
import { SongsExplorer } from '../components/SongsExplorer';

const Home = () => {
   const selection = useSelection();
   const selectionList = Object.keys(selection.selection);
   const selectionCount = selectionList.length;
   const [showDownloadModal, setShowDownloadModal] = useState(false);
   const [isLoading, albums] = useFetch<AlbumWithSongsDto[]>(routes.albums.withSongs, []);
   const sortedAlbums = useMemo(
      // eslint-disable-next-line no-nested-ternary
      () => (albums || []).sort((a, b) => (a.title > b.title ? 1 : a.title < b.title ? -1 : 0)),
      [albums],
   );

   return (
      <Box display="flex" justifyContent="center" pb={5}>
         {/* SONGS EXPLORER */}
         <Box boxSizing="border-box" maxWidth={1200} width="100%" p={2}>
            {isLoading && !albums ? (
               <Box minHeight={300} display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress />
               </Box>
            ) : (
               <SongsExplorer albums={sortedAlbums} selection={selection} />
            )}
         </Box>

         {/* DOWNLOAD BUTTON */}
         <Box
            style={{
               position: 'fixed',
               borderRadius: 50,
               background: 'white',
               bottom: 10,
               right: 10,
            }}
         >
            <Button
               color="primary"
               variant="contained"
               disabled={selectionCount <= 0}
               style={{ padding: 15, minWidth: 0, borderRadius: 50 }}
               onClick={() => setShowDownloadModal(true)}
            >
               {selectionCount > 0 ? (
                  <Badge badgeContent={selectionCount} color="error">
                     <GetAppIcon style={{ fontSize: 35 }} />
                  </Badge>
               ) : (
                  <GetAppIcon style={{ fontSize: 35 }} />
               )}
            </Button>
         </Box>

         {/* DOWNLOAD MODAL */}
         {showDownloadModal && (
            <Modal open onClose={() => setShowDownloadModal(false)}>
               <DownloadForm selectionList={selectionList} />
            </Modal>
         )}
      </Box>
   );
};

export default Home;
