import React from 'react';
import { Button, ButtonProps } from '@mui/material';

const PrimaryButton = ({
   style,
   mini,
   round,
   ...props
}: ButtonProps & { round?: boolean; mini?: boolean }) => (
      <Button
         style={{
            ...(mini ? { padding: '6px 6px', minWidth: 0, maxWidth: 100 } : {}),
            ...(round ? { borderRadius: 100 } : {}),
            ...style,
         }}
         {...props}
         variant={props.variant ?? 'contained'}
         color={props.color ?? 'primary'}
      />
   );

export default PrimaryButton;
