import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Autocomplete,
   Box,
   Chip,
   Divider,
   Paper,
   TextField,
   Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React, { useCallback, useMemo, useState } from 'react';
import { AlbumWithSongsDto } from '../dto/album';
import AudioButton from './AudioButton';
import { SelectionManager } from './Selection';

export const SongsExplorer = ({ selection, albums }: SongsExplorerProps) => {
   const tags = useMemo(() => extractTags(albums), [albums]);
   const tagsList = useMemo(() => Array.from(tags).sort(), [tags]);
   const [selectedTags, setSelectedTags] = useState(new Set<string>());
   const [mode, setMode] = useState<'and' | 'or'>('and');
   const [activeAlbumId, setActiveAlbumId] = useState<string | null>(null);
   const { displayedAlbums, songsCount, selectedSongsCount } = useMemo(
      () => filterAlbumsAndSongsByTag(albums, selectedTags, mode),
      [albums, selectedTags, mode],
   );

   const handleExpandAlbum = useCallback(
      (album: AlbumWithSongsDto, isExpanded: boolean) => {
         if (isExpanded) setActiveAlbumId(album.id);
         else setActiveAlbumId(null);
      },
      [setActiveAlbumId],
   );

   return (
      <Box>
         {/* SEARCH BAR */}
         <Paper
            style={{
               transition: 'all 0.5s',
               marginTop: 15,
               marginBottom: 15,
            }}
            variant="outlined"
         >
            <Box p={2}>
               <Box display="flex">
                  <Box flexGrow={1}>
                     <Autocomplete
                        multiple
                        id="tags-standard"
                        options={tagsList}
                        value={Array.from(selectedTags)}
                        onChange={(_ev, value) => setSelectedTags(new Set(value))}
                        renderInput={(params) => (
                           <TextField
                              {...params}
                              size="medium"
                              variant="outlined"
                              label="Sélectionnez les tags qui vous interesse..."
                           />
                        )}
                     />
                  </Box>
                  <ToggleButtonGroup
                     value={mode}
                     exclusive
                     size="medium"
                     style={{ marginLeft: 10 }}
                     onChange={(e, value) => setMode(value)}
                  >
                     <ToggleButton value="and">ET</ToggleButton>
                     <ToggleButton value="or">OU</ToggleButton>
                  </ToggleButtonGroup>
               </Box>
               <Box pt={2}>
                  <b>{displayedAlbums.length}</b> albums sur {albums.length} albums au total
                  {' - '} <b>{selectedSongsCount}</b> sur {songsCount} pistes audio
               </Box>
            </Box>
         </Paper>

         {/* ALBUMS LIST */}
         <Box>
            {displayedAlbums.map((album) => (
               <AlbumAccordion
                  key={album.id}
                  album={album}
                  selection={selection}
                  expanded={album.id === activeAlbumId}
                  onExpand={handleExpandAlbum}
               />
            ))}
         </Box>
      </Box>
   );
};
type SongsExplorerProps = {
   albums: AlbumWithSongsDto[];
   selection: SelectionManager;
};
type AlbumAccordionProps = {
   album: AlbumWithSongsDto;
   selection: SelectionManager;
   expanded: boolean;
   onExpand: (album: AlbumWithSongsDto, isExpanded: boolean) => void;
};
const AlbumAccordion = React.memo(
   ({ album, expanded, onExpand, selection }: AlbumAccordionProps) => (
      <Accordion
         style={{ marginTop: expanded ? 15 : -1 }}
         expanded={expanded}
         onChange={(e, isExpanded) => onExpand(album, isExpanded)}
         TransitionProps={{ unmountOnExit: true }}
         variant="outlined"
      >
         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ flexBasis: '45.33%', flexShrink: 0 }}>{album.title}</Typography>
            <Typography style={{ color: '#AAA' }}>
               {album.songs.length} piste{album.songs.length <= 1 ? '' : 's'} audio
            </Typography>
         </AccordionSummary>
         <AccordionDetails>
            {!!album.description && (
               <Paper variant="outlined" sx={{ mb: 2, p: 2 }}>
                  <Typography sx={{ color: grey[600] }}>{album.description}</Typography>
               </Paper>
            )}
            <Box
               width="100%"
               style={{
                  display: 'grid',
                  counterReset: 'grid-items',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(min(350px, 85vw), 1fr))',
                  rowGap: '10px',
                  columnGap: '10px',
                  alignItems: 'stretch',
               }}
            >
               {album?.songs?.map((song) => (
                  <Box
                     key={song.id}
                     boxSizing="border-box"
                     style={{
                        background: '#FFF',
                        border: '1px solid #DDD',
                        boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.06)',
                        borderRadius: '2px',
                     }}
                     px={2}
                     py={0.5}
                     flexGrow={1}
                  >
                     <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" alignItems="center">
                           <Box mr={1}>
                              {' '}
                              <AudioButton padding={0} size={23} song={song} />
                           </Box>
                           {song.title}
                        </Box>
                        <Box style={{ marginTop: 3 }}>
                           {selection.selection[song.id] ? (
                              <RemoveCircleIcon
                                 style={{ cursor: 'pointer', color: '#A44' }}
                                 onClick={() => selection.del(String(song.id))}
                              />
                           ) : (
                              <AddCircleIcon
                                 style={{ cursor: 'pointer', color: '#44A' }}
                                 onClick={() => selection.add(String(song.id))}
                              />
                           )}
                        </Box>
                     </Box>
                     <Box display="flex" flexWrap="wrap">
                        {song.tags.map((tag) => (
                           <Box m={0.5} key={tag.name}>
                              <Chip size="small" label={tag.name} />
                           </Box>
                        ))}
                     </Box>
                     {!!song.description && (
                        <>
                           <Divider sx={{ mt: 1 }} />
                           <Box py={1}>
                              <Typography sx={{ fontSize: 14, color: grey['600'] }}>
                                 {song.description}
                              </Typography>
                           </Box>
                        </>
                     )}
                  </Box>
               ))}
            </Box>
         </AccordionDetails>
      </Accordion>
   ),
);
const extractTags = (albums: AlbumWithSongsDto[]) => {
   const tags = new Set<string>();
   albums.forEach((album) => {
      album.songs.forEach((song) => {
         song.tags.forEach((tag) => {
            tags.add(tag.name);
         });
      });
   });
   return tags;
};
type FilterAlbumsAndSongsByTagResult = {
   displayedAlbums: AlbumWithSongsDto[];
   songsCount: number;
   selectedSongsCount: number;
};
const filterAlbumsAndSongsByTag = (
   albums: AlbumWithSongsDto[],
   selectedTags: Set<string>,
   mode: 'and' | 'or',
): FilterAlbumsAndSongsByTagResult => {
   const displayedAlbums: AlbumWithSongsDto[] = [];
   let songsCount = 0;
   let selectedSongsCount = 0;

   albums.forEach((album) => {
      const songs = [];
      album.songs.forEach((song) => {
         songsCount += 1;
         let shouldAddSong = false;
         if (mode === 'and') {
            shouldAddSong = true;
            selectedTags.forEach((tagName) => {
               shouldAddSong = shouldAddSong && !!song.tags.find((tag) => tag.name === tagName);
            });
         } else {
            shouldAddSong = selectedTags.size === 0;
            selectedTags.forEach((tagName) => {
               shouldAddSong = shouldAddSong || !!song.tags.find((tag) => tag.name === tagName);
            });
         }
         if (shouldAddSong) {
            songs.push({
               ...song,
               // eslint-disable-next-line no-nested-ternary
               tags: song.tags.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)),
            });
            selectedSongsCount += 1;
         }
      });
      if (songs.length > 0 || selectedTags.size === 0) displayedAlbums.push({ ...album, songs });
   });
   return { displayedAlbums, songsCount, selectedSongsCount };
};
