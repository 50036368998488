import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Edit } from '@mui/icons-material';
import React, { useState } from 'react';
import routes from '../api/routes';
import ErrorMessage from '../components/form/ErrorMessage';
import SubmitButton from '../components/form/SubmitButton';
import TextInput from '../components/form/TextInput';
import Modal from '../components/Modal';
import Title from '../components/Title';
import { UserDto } from '../dto/user';
import useFetch from '../hooks/useFetch';
import useForm from '../hooks/useForm';

const Users = () => {
   const [users, setUsers] = useState<UserDto[]>([]);
   useFetch<UserDto[]>(routes.users.all, [], (usersData) => setUsers(usersData));
   const [editUser, setEditUser] = useState<UserDto>(null);

   return (
      <Box display="flex" justifyContent="center" pb={5}>
         <Box boxSizing="border-box" maxWidth={900} width="100%" p={2}>
            <Title big>Gestion des utilisateurs</Title>
            <Title mb={1}>Créer un utilisateur</Title>
            <Box display="flex" flexWrap="wrap">
               <UserForm
                  init={new UserDto()}
                  onSuccess={(user) => setUsers([user, ...users])}
                  url={routes.users.create}
               />
            </Box>

            <Title mt={2} mb={1}>
               Liste des utilisateurs
            </Title>
            <Paper variant="outlined" style={{ overflow: 'auto' }}>
               <Table>
                  <TableHead>
                     <TableRow>
                        <TableCell>Prénom</TableCell>
                        <TableCell>Nom</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell style={{ width: 30 }}></TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {users.map((user) => (
                        <TableRow key={user.id}>
                           <TableCell> {user.firstName} </TableCell>
                           <TableCell> {user.lastName} </TableCell>
                           <TableCell> {user.email} </TableCell>
                           <TableCell style={{ width: 30 }}>
                              <Edit onClick={() => setEditUser(user)} />
                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </Paper>
         </Box>

         {editUser && (
            <Modal open={true} onClose={() => setEditUser(null)}>
               <Box maxWidth={300}>
                  <Title>Modifier l&apos;utilisateur</Title>
                  <UserForm
                     edit
                     init={editUser}
                     url={routes.users.edit({ id: editUser.id })}
                     onSuccess={(user) => {
                        setUsers(users.map((u) => (u.id === user.id ? user : u)));
                        setEditUser(null);
                     }}
                  />
               </Box>
            </Modal>
         )}
      </Box>
   );
};

const UserForm = ({ onSuccess, url, init, edit = false }) => {
   const form = useForm({
      init,
      url,
      onSuccess: (user, currentForm) => {
         currentForm.clear();
         onSuccess(user);
      },
   });

   const inputStyle = {
      margin: 2,
      marginTop: 8,
      marginBottom: 8,
   };

   return (
      <form onSubmit={form.submit} noValidate style={{ display: 'flex', flexWrap: 'wrap' }}>
         {form.errors?.global && <ErrorMessage>{form.errors.global}</ErrorMessage>}
         <TextInput
            required
            {...form.field('firstName')}
            width={200}
            label="Prénom"
            flexGrow={1}
            style={inputStyle}
         />
         <TextInput
            required
            {...form.field('lastName')}
            width={200}
            label="Nom"
            flexGrow={1}
            style={inputStyle}
         />
         <TextInput
            required
            {...form.field('email')}
            width={200}
            type="email"
            label="Email"
            flexGrow={1}
            style={inputStyle}
         />
         <TextInput
            required
            {...form.field('password')}
            width={200}
            label="Mot de passe"
            flexGrow={1}
            style={inputStyle}
         />
         <SubmitButton
            edit={edit}
            success={form.success}
            submitting={form.isSubmitting}
            style={{
               minHeight: 56,
               margin: 2,
               ...(edit
                  ? {}
                  : {
                       padding: 0,
                       paddingLeft: 10,
                    }),
            }}
         >
            {edit ? 'Modifier' : ''}
         </SubmitButton>
      </form>
   );
};

export default Users;
