import React, { useState } from 'react';
import { User } from '../../types/user';

const serializedUser = localStorage.getItem('user');
let savedUser: any;

try {
   savedUser = serializedUser ? JSON.parse(serializedUser) : null;
} catch (err) {
   savedUser = null;
   localStorage.removeItem('token');
}

export type UserContextProps = {
   user: User | null;
   setUser: (user: User | null) => any;
};
export const UserContext = React.createContext<UserContextProps>({
   user: savedUser,
   setUser: () => {},
});

const UserProvider = ({ children }: { children: any }) => {
   const [user, rawSetUser] = useState<User | null>(savedUser);

   const setUser = (newUser: User | null) => {
      if (newUser) {
         localStorage.setItem('user', JSON.stringify(newUser));
      } else {
         localStorage.removeItem('user');
      }
      rawSetUser(newUser);
   };

   return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};

export default UserProvider;
