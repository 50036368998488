const utils = (baseUrl: string) => {
   const utilsObj = {
      prefixRoute(route: string) {
         return baseUrl + (route[0] === '/' ? '' : '/') + route;
      },

      prefixRoutes<T>(routes: T): T {
         if (typeof routes === 'object') {
            const newRoutes: Record<string, any> = {};
            Array.from(Object.entries(routes)).forEach(([key, value]) => {
               newRoutes[key] = utilsObj.prefixRoutes(value) as any;
            });
            return newRoutes as T;
         }
         if (typeof routes === 'string') {
            return utilsObj.prefixRoute(routes) as any as T;
         }
         return routes;
      },

      paramsRoute:
         <T, Q extends object = Record<string, any>>(path: string) =>
         (params: T = {} as any, queryParams: Q = {} as any) => {
            let finalPath = path;
            const query = Object.entries(queryParams)
               .filter(([_, v]) => v !== undefined && v !== null)
               .map((kv) => kv.join('='))
               .join('&');
            Array.from(Object.entries(params)).forEach(([key, value]) => {
               finalPath = finalPath.replace(`:${key}`, String(value));
            });
            return utilsObj.prefixRoute(finalPath) + (query ? '?' : '') + query;
         },
   };

   return utilsObj;
};

export default utils;
