import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import UserProvider from './components/user/UserProvider';

const root = createRoot(document.getElementById('root'));

root.render(
   <UserProvider>
      <App />
   </UserProvider>,
);
