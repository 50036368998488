// eslint-disable-next-line max-classes-per-file
import { SongWithAlbumDto } from './song';
import { UserDto } from './user';

export class DownloadRequestDto {
   ids: string;

   comment: string;
}

export class DownloadRequestHistoryDto {
   comment: string;

   songs: SongWithAlbumDto[];

   user: UserDto;

   timestamp: string;
}
