/* eslint-disable no-nested-ternary */
import { Edit } from '@mui/icons-material';
import {
   Box,
   Button,
   Checkbox,
   CircularProgress,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Stack,
   Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { grey } from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { del, post } from '../api';
import routes from '../api/routes';
import AudioButton from '../components/AudioButton';
import FileUploader, { UploadSong } from '../components/files/FileUploader';
import DeleteButton from '../components/form/DeleteButton';
import ErrorMessage from '../components/form/ErrorMessage';
import SubmitButton from '../components/form/SubmitButton';
import TextInput from '../components/form/TextInput';
import PrimaryButton from '../components/PrimaryButton';
import { SongDto } from '../dto/song';
import { TagDto } from '../dto/tag';
import useFetch from '../hooks/useFetch';
import useForm from '../hooks/useForm';

const ManagerAlbumSongs = () => {
   const { id } = useParams<{ id: string }>();
   const [selection, setSelection] = useState<Record<string, UploadSong>>({});
   const [songs, setSongs] = useState<UploadSong[]>([]);
   const [uploadSongs, setUploadSongs] = useState<UploadSong[]>([]);
   const [suggestedTags, setSuggestedTags] = useState<TagDto[]>([]);
   useFetch<UploadSong[]>(routes.albums.songs({ id }), [], (songsData) => setSongs(songsData));
   useFetch<TagDto[]>(routes.songs.tags, [], (tags) => setSuggestedTags(tags));
   const [renameSong, setRenameSong] = useState(null);
   const [tags, setTags] = useState<string[]>([]);

   const allSongs = [...uploadSongs, ...songs];

   return (
      <Box>
         <Box p={2}>
            <FileUploader
               albumId={id}
               accept={{
                  'audio/*': ['.wav'],
               }}
               onUploadStatusChange={(usongs) => setUploadSongs(usongs)}
            />
         </Box>

         <Box>
            {allSongs.map((song) => (
               <Song
                  key={song.id}
                  song={song}
                  onTagDelete={(tag) => {
                     const newSong = { ...song, tags: song.tags.filter((t) => t.name !== tag) };
                     setSongs(songs.map((s) => (s.id === newSong.id ? newSong : s)));
                     setUploadSongs([...uploadSongs]);
                     del(
                        routes.songs.removeTag(
                           { songId: song.id },
                           { tag: encodeURIComponent(tag) },
                        ),
                     );
                  }}
                  setRenameSong={setRenameSong}
                  isSelected={!!selection[song.id]}
                  onDelete={() => setSongs(songs.filter((s) => s.id !== song.id))}
                  onSelectionChange={(checked) => {
                     if (checked) {
                        setSelection({ ...selection, [song.id]: song });
                     } else {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const { [song.id]: _, ...others } = selection;
                        setSelection(others);
                     }
                  }}
               />
            ))}
         </Box>

         <Box px={2}>
            <Autocomplete
               multiple
               id="tags-filled"
               options={suggestedTags.map((t) => t.name)}
               defaultValue={[]}
               freeSolo
               renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                     <Chip
                        key={option}
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                     />
                  ))
               }
               renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Tags" placeholder="Tags" />
               )}
               value={tags}
               onChange={(_, newTags) => {
                  setTags(newTags);
               }}
            />
            <Box mt={1} display="flex" justifyContent="end">
               <Button
                  disabled={!Object.keys(selection).length}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                     post(routes.songs.addTags, {
                        ids: Object.keys(selection),
                        tags,
                     }).then(() => {
                        Object.values(selection).forEach((song) => {
                           tags.forEach((tag) => {
                              if (!song.tags.find((t) => t.name === tag)) {
                                 song.tags.push({ name: tag });
                              }
                           });
                        });
                        setSongs([...songs]);
                        setUploadSongs([...uploadSongs]);
                     })
                  }
               >
                  Ajouter tags
               </Button>
            </Box>
         </Box>

         {renameSong && (
            <UdateModal
               close={() => setRenameSong(null)}
               onSuccess={(currentId, newTitle, description) => {
                  setSongs(
                     songs.map((song) =>
                        String(song.id) === String(currentId)
                           ? { ...song, title: newTitle, description }
                           : song,
                     ),
                  );
                  setUploadSongs(
                     uploadSongs.map((song) =>
                        String(song.id) === String(currentId)
                           ? { ...song, title: newTitle, description }
                           : song,
                     ),
                  );
                  setRenameSong(null);
               }}
               song={renameSong}
            />
         )}
      </Box>
   );
};

type SongProps = {
   song: UploadSong;
   isSelected: boolean;
   onSelectionChange: (checked: boolean) => void;
   setRenameSong: (song: SongDto) => void;
   onDelete: () => void;
   onTagDelete: (tag: string) => void;
};
const Song = ({
   song,
   isSelected,
   onSelectionChange,
   setRenameSong,
   onTagDelete,
   onDelete,
}: SongProps) => (
   <Box
      boxSizing="border-box"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      style={{
         background: '#FFF',
         border: '1px solid #DDD',
         boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.06)',
         borderRadius: '2px',
      }}
      my={1}
      mx={2}
      px={2}
      flexGrow={1}
      flexWrap="wrap"
   >
      <Box display="flex" alignItems="center" flexWrap="wrap" flexGrow={1}>
         <Box display="flex" alignItems="center" my={0.5}>
            {song.status === 'uploading' ? (
               <Box my={0.5} position="relative">
                  <CircularProgress
                     style={{ position: 'absolute', color: '#EEE' }}
                     size={23}
                     value={100}
                  />
                  <CircularProgress
                     size={23}
                     style={{ color: '#66A' }}
                     value={Math.round(song.progress * 100)}
                  />
               </Box>
            ) : song.status === 'processing' ? (
               <Box my={0.5} position="relative">
                  <CircularProgress
                     style={{ position: 'absolute', color: '#EEE' }}
                     size={23}
                     value={100}
                  />
                  <CircularProgress size={23} style={{ color: '#66A' }} />
               </Box>
            ) : song.status === 'error' ? (
               'Erreur'
            ) : (
               <AudioButton padding={0} size={23} song={song} />
            )}
            <Box textAlign="center" mx={2}>
               {song.title}{' '}
            </Box>
            <Box>
               <Typography sx={{ color: grey[600] }}>{song.description}</Typography>
            </Box>
         </Box>
      </Box>
      <Box style={{ marginTop: 3 }} display="flex" alignItems="center">
         <Box display="flex" flexWrap="wrap" mr={3}>
            {song.tags?.map?.((tag) => (
               <Box key={tag.name} mx={0.5} my={0.5}>
                  <Chip
                     label={tag.name}
                     onDelete={() => {
                        onTagDelete(tag.name);
                     }}
                  />
               </Box>
            ))}
         </Box>
         <Stack direction="row" spacing={1} alignItems="center">
            <PrimaryButton round mini onClick={() => setRenameSong(song)}>
               {song.id && <Edit style={{ cursor: 'pointer', fontSize: 14 }} />}
            </PrimaryButton>
            <DeleteButton
               mini
               round
               url={routes.songs.delete({ songId: song.id })}
               onSuccess={onDelete}
            />
         </Stack>
         <Checkbox value={isSelected} onChange={(e, c) => onSelectionChange(c)} color="primary" />
      </Box>
   </Box>
);

type UpdateModalProps = {
   song: SongDto;
   close: () => void;
   onSuccess: (id: string, newTitle: string, description: string) => void;
};

const UdateModal = ({ song, close, onSuccess }: UpdateModalProps) => {
   const form = useForm({
      init: { title: song.title, description: song.description, id: song.id },
      url: routes.songs.update({ songId: String(song.id) }),
      onSuccess: (_, currentForm) =>
         onSuccess(song.id, currentForm.values.title, currentForm.values.description),
   });

   return (
      <Dialog open onClose={close} maxWidth="xs" fullWidth>
         <form noValidate onSubmit={form.submit}>
            <DialogTitle>Modifier la piste audio</DialogTitle>
            <DialogContent>
               <Stack pt={1} spacing={2}>
                  {form.errors?.global && <ErrorMessage>{form.errors?.global}</ErrorMessage>}
                  <TextInput {...form.field('title')} fullWidth label="Nom du fichier son" />
                  <TextInput
                     {...form.field('description')}
                     fullWidth
                     multiline
                     label="Description"
                  />
               </Stack>
            </DialogContent>
            <DialogActions>
               <Button onClick={close}>Annuler</Button>
               <SubmitButton edit success={form.success} submitting={form.isSubmitting}>
                  Valider
               </SubmitButton>
            </DialogActions>
         </form>
      </Dialog>
   );
};

export default ManagerAlbumSongs;
