import React, { useEffect } from 'react';
import LoginPage from './pages/LoginPage';
import useAuth from './hooks/useAuth';
import { events } from './api/index';
import Main from './Main';

const App = () => {
   const { user, logout } = useAuth();
   useEffect(() => {
      events.on('auth_failed', logout);
      return () => {
         events.off('auth_failed', logout);
      };
   }, [logout]);
   return user ? <Main /> : <LoginPage />;
};

export default App;
