/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import Flow from '@flowjs/flow.js';
import { styled } from '@mui/material/styles';
import React, { useCallback, useRef } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import routes from '../../api/routes';
import { SongDto } from '../../dto/song';

export type UploadSong = SongDto & {
   title: string;
   status: 'uploading' | 'processing' | 'done' | 'error';
   progress: number;
   file: File;
   error: any;
};

export type FileUploaderProps = {
   albumId?: string;
   onUploadStatusChange?: (songs: UploadSong[]) => any;
   accept?: Accept;
};

const FileUploader = ({ albumId, onUploadStatusChange, accept }: FileUploaderProps) => {
   const uploadedFiles = useRef([] as any[]);

   const onDrop = useCallback(
      async (files: File[]) => {
         const previews = files.map((file) => ({
            title: file.name,
            status: 'uploading',
            progress: 0,
            file,
            error: null,
         }));
         uploadedFiles.current.unshift(...previews);
         onUploadStatusChange([...uploadedFiles.current]);

         await Promise.all(
            previews.map(async (preview) => {
               const flow = new Flow({
                  target: routes.songs.uploadPart,
                  query: { albumId },
                  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                  simultaneousUploads: 1,
                  testChunks: false,
               });

               flow.addFile(preview.file);
               flow.on('progress', () => {
                  preview.progress = flow.progress();
                  if (preview.progress > 0.99) preview.status = 'processing';
                  onUploadStatusChange([...uploadedFiles.current]);
               });
               flow.upload();

               await new Promise<void>((res) => {
                  flow.on('error', (err) => {
                     preview.error = err;
                     preview.status = 'error';
                     onUploadStatusChange([...uploadedFiles.current]);
                     res();
                  });

                  flow.on('fileSuccess', (_file, msg) => {
                     const data = JSON.parse(msg);
                     Object.assign(preview, data);
                     onUploadStatusChange([...uploadedFiles.current]);
                  });

                  flow.on('complete', () => {
                     flow.off();
                     res();
                  });
               });

               preview.status = 'done';
               onUploadStatusChange([...uploadedFiles.current]);
            }),
         );
      },
      [albumId, onUploadStatusChange],
   );

   const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
      onDrop,
      multiple: true,
      accept: accept || undefined,
   });

   return (
      <div>
         <DropZone {...getRootProps()} isDragAccept={isDragAccept} isDragReject={isDragReject}>
            <input {...getInputProps()} />
            {isDragAccept ? (
               <p>Déposer le fichier ici...</p>
            ) : isDragReject ? (
               <p style={{ color: '#FF1744' }}>Ce type de fichier n&apos;est pas accepté</p>
            ) : (
               <p>Glisser-déposer un ou plusieurs fichiers ici ou bien cliquez.</p>
            )}
         </DropZone>
      </div>
   );
};

const DropZone = styled('div')<{
   isDragAccept: boolean;
   isDragReject: boolean;
}>(({ isDragAccept, isDragReject }) => ({
   display: 'flex',
   cursor: 'pointer',
   alignItems: 'center',
   justifyContent: 'center',
   height: 100,
   textAlign: 'center',
   outline: 'none',
   borderWidth: 2,
   borderRadius: 2,
   borderColor: isDragAccept ? '#00e676' : isDragReject ? '#ff1744' : '#eeeeee',
   borderStyle: 'dashed',
   padding: 20,
}));

export default FileUploader;
