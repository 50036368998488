import { Box, Button, Link } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import React, { useState } from 'react';
import routes from '../api/routes';
import ErrorMessage from './form/ErrorMessage';
import SubmitButton from './form/SubmitButton';
import TextInput from './form/TextInput';
import Title from './Title';
import { DownloadRequestDto } from '../dto/downloadRequest';
import useForm from '../hooks/useForm';

export const DownloadForm = ({ selectionList }: { selectionList: string[] }) => {
   const [downloadToken, setDownloadToken] = useState(null);
   const form = useForm({
      init: new DownloadRequestDto(),
      url: routes.songs.downloadRequest,
      onSuccess: (dr) => setDownloadToken(dr.token),
   });

   return (
      <Box>
         {downloadToken ? (
            <Box
               display="flex"
               height="200px"
               width="min(400px, 90vw)"
               justifyContent="center"
               alignItems="center"
            >
               <Link href={`${routes.songs.download({ token: downloadToken })}`}>
                  <Button variant="contained" color="primary" style={{ flexWrap: 'wrap' }}>
                     <GetAppIcon style={{ fontSize: 35 }} />
                     <Box width="100%"></Box>
                     Télécharger
                  </Button>
               </Link>
            </Box>
         ) : (
            <form
               noValidate
               style={{ width: 'min(400px, 90vw)' }}
               onSubmit={(e) => form.submit(e, { ids: selectionList.join(',') })}
            >
               <Title mb={2}>Télécharger la sélection</Title>
               {form?.errors?.global && <ErrorMessage>{form?.errors?.global}</ErrorMessage>}
               <Box>
                  <TextInput
                     required
                     fullWidth
                     multiline
                     rows={4}
                     label="Motif"
                     {...form.field('comment')}
                  />
               </Box>
               <Box display="flex" justifyContent="end" mt={1}>
                  <SubmitButton icon={false} submitting={form.isSubmitting} success={form.success}>
                     Obtenir lien de téléchargement
                  </SubmitButton>
               </Box>
            </form>
         )}
      </Box>
   );
};
