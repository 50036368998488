import { useRef, useState } from 'react';
import { post } from '../api';

export type FormParams<DTO extends Object> = {
   init?: DTO;
   values?: DTO;
   onChange?: (value: DTO) => any;
   url?: string;
   validation?: (
      values: DTO,
      addError: (field: keyof DTO | 'global', message: string) => any,
   ) => any;
   onSuccess?: (answer: any, form: Form<DTO>) => void;
   onError?: (errors: any) => void;
};

export type Subforms<DTO extends Object> = {
   [K in keyof DTO]?: DTO[K] extends Object ? Form<DTO[K]> : never;
};

export type Errors<DTO extends Object> = Record<keyof DTO | 'global', string> | null;

export type Form<DTO> = {
   subforms: Subforms<DTO>;
   addSubform: <K extends keyof DTO>(name: K, subform: Form<DTO[K]>) => Form<DTO>;
   field: <K extends keyof DTO>(
      name: K,
   ) => {
      name: K;
      value: DTO[K];
      inputRef: (node: any) => any;
      onChange: (value: DTO[K]) => void;
      error: string;
   };
   values: DTO;
   errors: Errors<DTO>;
   setErrors: (errors: Errors<DTO>) => any;
   clearErrors: () => void;
   clear: () => void;
   setValues: (values: DTO) => void;
   isSubmitting: boolean;
   validate: () => [boolean, Errors<DTO>];
   submit: (event: React.FormEvent<HTMLFormElement>, overwriteValues?: Partial<DTO>) => any;
   success: boolean;
};

const useForm = <DTO>({
   init,
   onSuccess,
   onError,
   url,
   validation,
   values: outSideValues,
   onChange,
}: FormParams<DTO>) => {
   const [success, setSuccess] = useState(false);
   const [errors, setErrors] = useState<Errors<DTO>>(null);
   const [stateValues, setFormValues] = useState(copy(init));
   const [isSubmitting, setIsSubmitting] = useState(false);
   const refs = useRef<Record<string, HTMLElement>>({});
   const values: DTO = (outSideValues || stateValues || {}) as any as DTO;
   const setValues = onChange || setFormValues;

   const setValue = <K extends keyof DTO>(name: K, value: DTO[K]) => {
      const newValues = copy(values);
      newValues[name] = value;
      setValues(newValues);
   };

   const form: Form<DTO> = {
      subforms: {},
      addSubform: <K extends keyof DTO>(name: K, subform: Form<DTO[K]>) => {
         form.subforms[name] = subform as any;
         return form;
      },
      field: <K extends keyof DTO>(name: K) => ({
         name,
         value: values[name] as DTO[K],
         inputRef: (node: any) => {
            refs.current[name as string] = node;
         },
         onChange: (value: any) => setValue(name, value),
         error: errors?.[name] || '',
      }),
      values: values as DTO,
      errors,
      success,
      setErrors,
      isSubmitting,
      setValues: (newValues: DTO) => setValues(newValues),
      clearErrors: () => setErrors(null),
      clear: () => {
         setSuccess(false);
         setValues(init);
         setErrors(null);
         setIsSubmitting(false);
      },
      validate(): [boolean, Errors<DTO>] {
         let isFormValid = true;
         const formErrors: Errors<DTO> = {} as any;
         const addError = (name: 'global' | keyof DTO, message: string) => {
            isFormValid = false;
            if (formErrors) formErrors[name] = message;
         };

         Array.from(Object.entries(refs.current)).forEach(([name, input]) => {
            const inputValid = (input as any)?.checkValidity?.();
            if (!inputValid) {
               if (formErrors) {
                  formErrors[name as keyof DTO] = (input as any).validationMessage as string;
                  (input as any).scrollIntoView({
                     behavior: 'smooth',
                     block: 'center',
                     inline: 'nearest',
                  });
               }
            }
            isFormValid = isFormValid && inputValid;
         });

         Array.from(Object.entries(form.subforms)).forEach(([_, subform]) => {
            const [isSubformValid] = (subform as Form<Object>).validate();
            isFormValid = isFormValid && isSubformValid;
         });

         if (Object.keys(formErrors || {}).length) setErrors(formErrors);
         else setErrors(null);

         const res = validation?.(values, addError);
         if (typeof res === 'boolean') isFormValid = isFormValid && res;
         return [isFormValid, formErrors];
      },
      submit: (event: React.FormEvent<HTMLFormElement>, overwriteValues?: Partial<DTO>) => {
         form.clearErrors();
         Object.values(form.subforms).forEach((subform) => {
            (subform as any).clearErrors();
         });

         event.preventDefault();
         const [isFormValid] = form.validate();

         if (isFormValid) {
            if (!url)
               return setErrors({
                  global: "L'url du formulaire est invalide!",
               } as Record<keyof DTO | 'global', string>);

            let formValues = { ...values } as any;

            Object.entries(form.subforms).forEach(([key, subform]) => {
               formValues[key] = (subform as any).values;
            });

            formValues = { ...formValues, ...overwriteValues };

            post(url, formValues).then((result) => {
               if (result.errors) {
                  setErrors(result.errors);
                  onError?.(result);
                  setIsSubmitting(false);
               } else {
                  setErrors(null);
                  setIsSubmitting(false);
                  setSuccess(true);
                  onSuccess?.(result, form);
               }
            });
            setIsSubmitting(true);
         }
         return false;
      },
   };

   return form;
};

const copy = (object: any) =>
   object ? Object.assign(Object.create(Object.getPrototypeOf(object)), object) : object;

export default useForm;
