// eslint-disable-next-line max-classes-per-file
export class UserDto {
   id: string = '';

   firstName: string = '';

   lastName: string = '';

   password: string = '';

   email: string = '';

   role: string = '';
}

export class CreateUserDto extends UserDto {}

export class HumanResourcesRowDto extends UserDto {
   totalLate: number;

   totalAbsent: number;

   avatarPath: string;

   nbMissions: number;
}
