/* eslint-disable no-nested-ternary */
import { Add, CheckCircle, Edit } from '@mui/icons-material';
import { Box, Button, ButtonProps, CircularProgress } from '@mui/material';
import React from 'react';

type SubmitButtonProps = ButtonProps & {
   edit?: boolean;
   submitting?: boolean;
   success?: boolean;
   icon?: boolean;
   children?: any;
};

const SubmitButton = ({
   edit,
   submitting,
   success,
   children,
   icon = true,
   ...props
}: SubmitButtonProps) => (
   <Button
      type="submit"
      color="primary"
      variant="contained"
      disabled={submitting || success}
      {...props}
   >
      {success ? (
         <CheckCircle color="primary" />
      ) : submitting ? (
         <CircularProgress size={24} />
      ) : (
         <>
            {icon && (edit ? <Edit /> : <Add />)}
            <Box ml={icon ? 1 : 0}>{children}</Box>
         </>
      )}
   </Button>
);

export default SubmitButton;
