import React, { useRef, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

const ErrorMessage = ({ children }: { children: string }) => {
   const ref = useRef<HTMLDivElement>();

   useEffect(() => {
      ref.current?.scrollIntoView({
         behavior: 'smooth',
         block: 'center',
         inline: 'nearest',
      });
   }, [children]);

   return (
      <Box py={2} color="error">
         <div ref={ref as any}></div>
         <Typography sx={{ whiteSpace: 'pre-wrap' }} color="error">
            {children}
         </Typography>
      </Box>
   );
};

export default ErrorMessage;
