import { useEffect, useState } from 'react';
import { get } from '../api';
/**
 * returns [isLoading, data, error]
 */
function useFetch<T = any, E = any>(
   url: string,
   watchProps: 'always' | any[] = [],
   callback?: (data: T) => any,
): [boolean, T, E, () => void] {
   const [{ isLoading, data, error }, setState] = useState({
      isLoading: true,
      data: null,
      error: null,
   });

   async function fetchData() {
      try {
         if (!isLoading) setState({ isLoading: true, data: null, error: null });
         const responseData = await get(url);
         callback?.(responseData);
         setState({ isLoading: false, data: responseData, error: null });
      } catch (err) {
         setState({ isLoading: false, data: null, error: err });
      }
   }

   useEffect(
      () => {
         fetchData();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      watchProps === 'always' ? undefined : watchProps,
   );
   return [isLoading, data as any as T, error as any as E, fetchData];
}

export default useFetch;
