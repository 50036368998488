import utils from './utils';

let baseUrl;

if (process.env.NODE_ENV === 'production') {
   baseUrl = 'https://musique.dominiquemassa.com/api';
} else if (process.env.REACT_APP_IS_LOCAL) {
   baseUrl = 'http://localhost:60112';
} else {
   baseUrl = 'https://musique.dominiquemassa.com/api';
}

const { paramsRoute, prefixRoutes } = utils(baseUrl);

const routes = {
   root: '/',
   auth: {
      login: '/auth/login',
   },
   songs: {
      upload: '/songs/upload',
      uploadPart: '/songs/upload-part',
      downloadRequest: '/songs/download-requests',
      download: paramsRoute<{ token: string }>('/songs/download-requests/:token'),
      downloadHistory: '/songs/download-requests/history',
      update: paramsRoute<{ songId: string }>('/songs/:songId'),
      delete: paramsRoute<{ songId: string }>('/songs/:songId'),
      addTags: '/songs/tags',
      removeTag: paramsRoute<{ songId: string }, { tag: string }>('/songs/:songId/tags'),
      tags: '/songs/tags',
      archiveAll: '/songs/archiveAll',
   },
   albums: {
      create: '/songs/albums',
      all: '/songs/albums',
      delete: paramsRoute<{ id: string }>('/songs/albums/:id'),
      songs: paramsRoute<{ id: string }>('/songs/albums/:id'),
      withSongs: '/songs/albums/with-songs',
      updateAlbum: paramsRoute<{ albumId: string }>('/songs/albums/:albumId'),
   },
   users: {
      all: '/users',
      create: '/users',
      edit: paramsRoute<{ id: string }>('/users/:id'),
   },
} as const;

export default prefixRoutes(routes);
