/* eslint-disable no-nested-ternary */
import React from 'react';
import { Typography, Box, BoxProps } from '@mui/material';

type TitleProps = {
   children?: any;
   big?: boolean;
   small?: boolean;
   className?: string;
   style?: React.CSSProperties;
} & BoxProps;
const Title = ({ children, big, small, className, ...props }: TitleProps) => (
   <Box {...props}>
      <Typography
         sx={{
            fontVariant: 'small-caps',
            color: '#222',
            fontSize: small ? 15 : big ? 22 : 18,
            margin: big ? '10px 0px' : undefined,
         }}
         className={className}
      >
         {children}
      </Typography>
   </Box>
);

export default Title;
