import { Box, Button } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import React from 'react';
import routes from '../../api/routes';
import useAuth from '../../hooks/useAuth';
import useForm from '../../hooks/useForm';
import ErrorMessage from '../form/ErrorMessage';
import TextInput from '../form/TextInput';

type LoginFormProps = {
   style?: React.CSSProperties;
};

const LoginForm = ({ style }: LoginFormProps) => {
   const { login } = useAuth();
   const form = useForm({
      url: routes.auth.login,
      init: { email: '', password: '' },
      onSuccess: (data) => login(data.user, data.token),
   });

   return (
      <form noValidate onSubmit={form.submit} style={{ width: '100%', maxWidth: 450, ...style }}>
         <ErrorMessage>{form.errors?.global || ' '}</ErrorMessage>

         <Box style={{ width: 'min(450px, 90vw)' }}>
            <TextInput
               {...form.field('email')}
               label="Adresse email"
               type="email"
               variant="outlined"
               fullWidth
               icon={<AccountCircle />}
            />
         </Box>
         <Box my={2} style={{ width: 'min(450px, 90vw)' }}>
            <TextInput
               {...form.field('password')}
               label="Mot de passe"
               type="password"
               variant="outlined"
               fullWidth
               icon={<LockIcon />}
            />
         </Box>
         <Box>
            <Button
               type="submit"
               disabled={!(form.values.email && form.values.password) || form.isSubmitting}
               fullWidth
               size="large"
               variant="contained"
               color="primary"
            >
               Se connecter
            </Button>
         </Box>
      </form>
   );
};

export default LoginForm;
