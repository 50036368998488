import React from 'react';
import IconButton from '@mui/material/IconButton';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PauseCircleFilledOutlinedIcon from '@mui/icons-material/PauseCircleFilledOutlined';
import { usePlayer } from "./Player";
import { SongDto } from '../dto/song';

const AudioButton = ({
   song,
   size = 60,
   padding = 12,
}: {
   song: SongDto;
   size?: number;
   padding?: number;
}) => {
   const player = usePlayer();
   const buttonAction =
      player?.song?.id !== song?.id || player.status === 'paused'
         ? () => {
              player.playSong(song);
           }
         : () => {
              player.pauseSong();
           };

   return (
      <IconButton onClick={buttonAction} style={{ padding }}>
         {player?.song?.id !== song?.id || player.status === 'paused' ? (
            <PlayCircleFilledIcon style={{ fontSize: size }} />
         ) : (
            <PauseCircleFilledOutlinedIcon style={{ fontSize: size }} />
         )}
      </IconButton>
   );
};

export default AudioButton;
