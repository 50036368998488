import React, { useState } from 'react';
import { Box, IconButton, useTheme, useMediaQuery, styled } from '@mui/material';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Menu as MenuIcon } from '@mui/icons-material';
import SideMenu from './components/SideMenu';
import TopBar from './components/TopBar';

import Home from './pages/Home';
import Users from './pages/Users';
import History from './pages/History';
import ManageAlbums from './pages/ManageAlbums';
import ManageAlbumSongs from './pages/ManageAlbumSongs';
import { PlayerProvider } from './components/Player';
import { SelectionProvider } from './components/Selection';

const Main = () => {
   const [open, setOpen] = useState(false);
   const theme = useTheme();
   const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));

   return (
      <Router>
         <PlayerProvider>
            <SelectionProvider>
               <Box display="flex">
                  <SideMenu open={open} close={() => setOpen(false)} />
                  <Box width="100%" pt={12}>
                     <TopBar />
                     <Box width="100%">
                        <Switch>
                           <Route exact path="/" component={Home} />
                           <Route exact path="/albums" component={ManageAlbums} />
                           <Route exact path="/albums/:id/songs" component={ManageAlbumSongs} />
                           <Route exact path="/users" component={Users} />
                           <Route exact path="/history" component={History} />
                        </Switch>
                     </Box>
                  </Box>
                  <Box
                     position="fixed"
                     bottom={0}
                     left={0}
                     right={0}
                     display="flex"
                     justifyContent="center"
                  >
                     {!isBigScreen && (
                        <MenuButton
                           onClick={() => setOpen(true)}
                           edge="start"
                           color="inherit"
                           aria-label="menu"
                        >
                           <MenuIcon />
                        </MenuButton>
                     )}
                  </Box>
               </Box>
            </SelectionProvider>
         </PlayerProvider>
      </Router>
   );
};

const MenuButton = styled(IconButton)({
   position: 'absolute',
   bottom: 10,
   left: '50vw',
   marginLeft: '-20px',
   background: 'white',
   boxShadow: '0px 0px 5px #00000044',
   '&:hover': {
      background: '#EEE',
   },
});

export default Main;
