import { Box } from '@mui/material';
import React from 'react';
import LoginForm from '../components/user/LoginForm';

const LoginPage = () => (
      <Box
         px={2}
         display="flex"
         justifyContent="center"
         alignItems="center"
         style={{ height: '100vh' }}
      >
         <Box display="flex" flexDirection="column">
            <LoginForm style={{ width: '100%', maxWidth: 450 }} />
         </Box>
      </Box>
   );

export default LoginPage;
