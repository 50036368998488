import { AppBar, Box, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import Player from './Player';

const TopBar = () => {
   const theme = useTheme();
   const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));

   return (
      <AppBar
         position="fixed"
         color="transparent"
         sx={{
            top: 0,
            width: isBigScreen ? 'calc(100vw - 305px)' : 'calc(100vw - 5px)',
            left: isBigScreen ? 300 : 0,
            boxShadow: 'none',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            background: 'white',
         }}
      >
         <Toolbar>
            <Box display="flex" justifyContent="center" alignItems="center" width="100%">
               <Box width="100%" maxWidth="900px" py={1}>
                  <Player />
               </Box>
            </Box>
         </Toolbar>
      </AppBar>
   );
};

export default TopBar;
