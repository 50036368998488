import { Delete, Edit } from '@mui/icons-material';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import FolderIcon from '@mui/icons-material/Folder';
import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Fab,
   Stack,
   styled,
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { del } from '../api';
import routes from '../api/routes';
import ErrorMessage from '../components/form/ErrorMessage';
import SubmitButton from '../components/form/SubmitButton';
import TextInput from '../components/form/TextInput';
import Modal from '../components/Modal';
import { AlbumDto } from '../dto/album';
import useFetch from '../hooks/useFetch';
import useForm from '../hooks/useForm';

const ManageAlbums = () => {
   const [albums, setAlbums] = useState([]);
   const [showModal, setShowModal] = useState(false);
   const [renamedAlbum, setRenamedAlbum] = useState<{
      id: string;
      title: string;
      description: string;
   } | null>(null);
   const [deletedAlbum, setDeletedAlbum] = useState<{ id: string; title: string } | null>(null);
   const form = useForm<AlbumDto>({
      init: new AlbumDto(),
      url: routes.albums.create,
      onSuccess: (ans, currentForm) => {
         currentForm.clear();
         setAlbums([ans, ...albums]);
         setShowModal(false);
      },
   });

   useFetch(routes.albums.all, [], (albumsData) => setAlbums(albumsData));

   const handleCloseDeleteDialog = () => {
      setDeletedAlbum(null);
   };

   const confirmDeleteAlbum = async () => {
      await del(routes.albums.delete({ id: deletedAlbum.id }));
      setAlbums((currentAlbums) => currentAlbums.filter((album) => album.id !== deletedAlbum.id));
      setDeletedAlbum(null);
   };

   return (
      <Box>
         {showModal && (
            <Modal open onClose={() => setShowModal(false)}>
               <Box style={{ width: 'calc(min(400px, 100vw - 40px))' }}>
                  <form noValidate onSubmit={form.submit}>
                     {form.errors?.global && <ErrorMessage>{form.errors?.global}</ErrorMessage>}
                     <TextInput {...form.field('title')} fullWidth label="Nom de l'album" />
                     <Box mt={2} display="flex" justifyContent="end">
                        <SubmitButton success={form.success} submitting={form.isSubmitting}>
                           Valider
                        </SubmitButton>
                     </Box>
                  </form>
               </Box>
            </Modal>
         )}

         <Grid>
            <Box display="flex" justifyContent="center">
               <Button
                  size="small"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowModal(true)}
               >
                  <Box px={2}>
                     <Box my={-0.5}>
                        <CreateNewFolderIcon style={{ color: '#888', fontSize: 120 }} />
                     </Box>
                     <Box pb={1} textAlign="center" style={{ color: '#444' }}>
                        Nouvel album
                     </Box>
                  </Box>
               </Button>
            </Box>

            {albums.map((album) => (
               <Box
                  key={album.title}
                  display="flex"
                  justifyContent="center"
                  position="relative"
                  sx={{ opacity: album.id === deletedAlbum?.id ? 0.5 : 1 }}
               >
                  <Link to={`/albums/${album.id}/songs`}>
                     <Button style={{ cursor: 'pointer' }} size="small">
                        <Box px={2}>
                           <Box my={-0.5}>
                              <FolderIcon style={{ color: '#888', fontSize: 120 }} />
                           </Box>
                           <Box pb={1} textAlign="center" style={{ color: '#444' }}>
                              {album.title}
                           </Box>
                        </Box>
                     </Button>
                  </Link>
                  <Fab
                     color="primary"
                     sx={{ position: 'absolute', top: 10, right: 10 }}
                     size="small"
                     onClick={() => {
                        setRenamedAlbum(album);
                     }}
                  >
                     <Edit />
                  </Fab>
                  <Fab
                     color="error"
                     sx={{
                        position: 'absolute',
                        top: 58,
                        right: 15,
                        width: 30,
                        minHeight: 30,
                        height: 30,
                     }}
                     size="small"
                     onClick={() => setDeletedAlbum(album)}
                  >
                     <Delete sx={{ fontSize: 18 }} />
                  </Fab>
               </Box>
            ))}
            {renamedAlbum && (
               <UpdateModal
                  album={renamedAlbum}
                  onSuccess={(id, title, description) => {
                     setRenamedAlbum(null);
                     setAlbums(
                        albums.map((album) =>
                           album.id === id ? { ...album, title, description } : album,
                        ),
                     );
                  }}
                  close={() => setRenamedAlbum(null)}
               />
            )}
            {deletedAlbum && (
               <Dialog open={!!deletedAlbum} onClose={handleCloseDeleteDialog}>
                  <DialogTitle>{"Supprimer l'album"}</DialogTitle>
                  <DialogContent>
                     <DialogContentText id="alert-dialog-description">
                        Voulez-vous vraiment supprimer l&apos;album &apos;
                        <strong>{deletedAlbum.title}</strong>&apos; ?
                     </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={handleCloseDeleteDialog}>Annuler</Button>
                     <Button
                        variant="contained"
                        color="error"
                        onClick={confirmDeleteAlbum}
                        autoFocus
                     >
                        {"Supprimer l'album"}
                     </Button>
                  </DialogActions>
               </Dialog>
            )}
         </Grid>
      </Box>
   );
};

const Grid = styled('div')({
   position: 'relative',
   display: 'grid',
   padding: 'min(max(10px, 2vw), 30px)',
   counterReset: 'grid-items',
   gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))',
});

type UpdateModalProps = {
   album: { id: string; title: string; description: string };
   close: () => void;
   onSuccess: (id: string, newTitle: string, newDescription: string) => void;
};
const UpdateModal = ({ close, onSuccess, album }: UpdateModalProps) => {
   const form = useForm({
      init: { title: album.title, description: album.description, id: album.id },
      url: routes.albums.updateAlbum({ albumId: String(album.id) }),
      onSuccess: (_, currentForm) =>
         onSuccess(album.id, currentForm.values.title, currentForm.values.description),
   });

   return (
      <Dialog open onClose={close} maxWidth="xs" fullWidth>
         <form noValidate onSubmit={form.submit}>
            <DialogTitle>{"Modifier l'album"}</DialogTitle>
            <DialogContent>
               <Stack pt={1} spacing={2}>
                  {form.errors?.global && <ErrorMessage>{form.errors?.global}</ErrorMessage>}
                  <TextInput {...form.field('title')} fullWidth label="Nom de l'album" />
                  <TextInput
                     {...form.field('description')}
                     fullWidth
                     multiline
                     label="Description"
                  />
               </Stack>
            </DialogContent>
            <DialogActions>
               <Button onClick={close}>Annuler</Button>
               <SubmitButton edit success={form.success} submitting={form.isSubmitting}>
                  Valider
               </SubmitButton>
            </DialogActions>
         </form>
      </Dialog>
   );
};

export default ManageAlbums;
